import React from 'react';

import './styles.scss';

function Button({
  children,
  size,
  type = 'button',
  color,
  leftIcon,
  rightIcon,
  isDisabled,
  onClick,
}) {
  return (
    <button
      disabled={isDisabled}
      className={`button button___${size} button___${color}`}
      type={type}
      onClick={onClick}
    >
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  );
}

export default Button;
