import React from 'react';

import Header from 'widgets/header';

import 'styles/root.scss';

function Layout({ children }) {
  return (
    <>
      <Header />
      <main>{children}</main>
    </>
  );
}

export default Layout;
