import React, { useState } from 'react';
import { Link } from 'gatsby';

import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';

import LogoLight from 'assets/logo/light.inline.svg';
import LogoDark from 'assets/logo/dark.inline.svg';
import Dots from 'assets/home/dots.inline.svg';
import Triangle from 'assets/home/triangle.inline.svg';

import { ROUTES, ROUTE_NAMES, LINKS } from 'content';

import './styles.scss';
import Button from 'components/button';
import useAnimations from './useAnimations';
import Icon from 'components/icon';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const titleString = (() => {
    if (pathname.includes(ROUTES.ABOUT_US)) {
      return 'About Us';
    } else if (pathname.includes(ROUTES.SERVICES)) {
      return 'Our Services';
    } else if (pathname.includes(ROUTES.PORTFOLIO)) {
      return 'Our Portfolio';
    } else if (pathname.includes(ROUTES.BLOGS)) {
      return 'All Blogs';
    }

    return null;
  })();

  const isInHome = pathname === ROUTES.HOME;
  const isInContact = pathname.includes(ROUTES.CONTACT_US);

  const {
    highlightRef,
    wordRef,
    headerRef,
    navRef,
    heroRef,
    heroTextRef,
    heroImageRef,
    titleRef,
    leftDotsRef,
    rightDotsRef,
    triangleRef,
    blackBoxRef,
    blackRectRef,
    whiteRectRef,
  } = useAnimations({ titleString, isInContact, isInHome });

  const [firstWord, secondWord] = titleString ? titleString.split(' ') : [];

  const logo = isInContact ? <LogoDark /> : <LogoLight />;
  const icon = isMenuOpen ? 'close' : 'menu';

  const onMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
    const body = document.getElementsByTagName('body')[0];
    if (!isMenuOpen) body.style.overflow = 'hidden';
    else body.style.overflow = 'auto';
  };

  const onLinkClick = () => {
    setIsMenuOpen(false);
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'auto';
  };

  return (
    <header
      ref={headerRef}
      className={`header grid ${isInContact ? 'header___dark' : ''}`}
    >
      <nav ref={navRef} className="col-full navbar">
        <Link className="navbar_logo" to={ROUTES.HOME}>
          {logo}
        </Link>
        <div className={`navbar_links ${isMenuOpen ? 'navbar_links___shown' : ''}`}>
          {LINKS.map((link) => (
            <Link
              key={link.to}
              className={`${
                pathname.includes(link.to) ? 'navbar_link___selected' : 'navbar_link'
              } ${isInContact ? 'navbar_link___dark' : ''}`}
              to={link.to}
              onClick={onLinkClick}
            >
              {link.name}
            </Link>
          ))}
          <Link to={ROUTES.CONTACT_US} onClick={onLinkClick}>
            <Button size="lg" color="dark">
              {ROUTE_NAMES[ROUTES.CONTACT_US]}
            </Button>
          </Link>
        </div>
        <button className="navbar_button" type="button" onClick={onMenuClick}>
          <Icon className="navbar_button_icon" color="dark" name={icon} />
        </button>
      </nav>
      {!isInContact && (
        <>
          <div ref={titleRef} className="header_title">
            <h1 className="heading-1">{firstWord}</h1>
            <div className="highlight">
              <h1 ref={wordRef} className="heading-1 highlight_text">
                {secondWord}
              </h1>
              <div ref={highlightRef} className="highlight_box" />
            </div>
            <h1 className="heading-1 highlight_text___hidden">{secondWord}</h1>
          </div>
          <div ref={heroRef} className="hero col-full">
            <div ref={heroTextRef} className="hero_text">
              <h2 className="hero_heading">
                Empowering businesses through{' '}
                <span className="hero_heading_highlight">innovative</span> software
                solutions
              </h2>
              <p className="text-1">
                Software Lab helps clients make their plans come to life by providing
                high-quality service from developers across the globe.
              </p>
            </div>
            <div className="hero_graphic">
              <div className="hero_graphic_image" ref={heroImageRef}>
                <StaticImage src="../../assets/home/globe.png" alt="Globe" />
              </div>
            </div>
          </div>
          <div ref={leftDotsRef} className="shapes_dots___left">
            <Dots />
          </div>
          <div ref={rightDotsRef} className="shapes_dots___right">
            <Dots />
          </div>
          <div ref={triangleRef} className="shapes_triangle">
            <Triangle />
          </div>
          <div ref={blackBoxRef} className="shapes_box" />
          <div ref={blackRectRef} className="shapes_rect___black" />
          <div ref={whiteRectRef} className="shapes_rect___white" />
        </>
      )}
    </header>
  );
}

export default Header;
