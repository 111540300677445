import { useEffect, useRef } from 'react';
import gsap, { Back, Power4 } from 'gsap';

const useHeaderAnimations = ({ titleString, isInContact, isInHome }) => {
  const highlightRef = useRef();
  const wordRef = useRef();
  const headerRef = useRef();
  const navRef = useRef();
  const heroRef = useRef();
  const heroTextRef = useRef();
  const heroImageRef = useRef();
  const titleRef = useRef();
  const leftDotsRef = useRef();
  const rightDotsRef = useRef();
  const triangleRef = useRef();
  const blackBoxRef = useRef();
  const blackRectRef = useRef();
  const whiteRectRef = useRef();

  gsap.defaults({
    duration: 1,
    ease: Power4.easeOut,
  })

  useEffect(() => {
    const highlight = highlightRef?.current;
    const word = wordRef?.current;
    const header = headerRef?.current;
    const nav = navRef?.current;
    const hero = heroRef?.current;
    const heroText = heroTextRef?.current;
    const heroImage = heroImageRef?.current;
    const title = titleRef?.current;
    const leftDots = leftDotsRef?.current;
    const rightDots = rightDotsRef?.current;
    const triangle = triangleRef?.current;
    const blackBox = blackBoxRef?.current;
    const blackRect = blackRectRef?.current;
    const whiteRect = whiteRectRef?.current;

    if (header) {
      if (isInHome) {
        // Home Page
        gsap.set(hero, {
          top: nav.scrollHeight + 56,
        })
        gsap.to(hero, {
          zIndex: 2,
          opacity: 1,
        })
        gsap.to(heroText, {
          translateX: 0,
        })
        gsap.set(title, {
          zIndex: -1,
        })
        gsap.to(title, {
          opacity: 0,
        })

        const imageRect = heroImage.getBoundingClientRect();
        const headerRect = header.getBoundingClientRect();
        const rightDotsRect = {
          top: imageRect.top + 128,
          right: headerRect.width - imageRect.right + imageRect.width - 110,
          height: 179,
        }
        gsap.to(rightDots, {
          top: rightDotsRect.top,
          right: rightDotsRect.right,
          ease: Back.easeOut,
        });
        const triangleRect = {
          top: rightDotsRect.top + rightDotsRect.height,
          right: headerRect.width - imageRect.right + imageRect.width - 1,
          height: 76,
        }
        gsap.to(triangle, {
          top: triangleRect.top,
          right: triangleRect.right,
          rotation: 0,
          ease: Back.easeOut,
        })
        gsap.to(blackBox, {
          top: triangleRect.top + triangleRect.height,
          left: imageRect.left - 76,
          height: 82,
          rotation: 0,
          ease: Back.easeOut,
        })
        gsap.to(whiteRect, {
          top: triangleRect.top,
          right: triangleRect.right - 128,
          rotation: 0,
          ease: Back.easeOut,
        })
        gsap.to(blackRect, {
          top: imageRect.top + 20,
          right: headerRect.width - imageRect.right - 55,
          opacity: 1,
          ease: Back.easeOut,
        })
        gsap.to(leftDots, {
          top: nav.scrollHeight + 140,
          scale: 0.85,
          ease: Back.easeOut,
        });

        gsap.to(header, {
          height: nav.scrollHeight + hero.scrollHeight + 152,
        });
      } else if (!isInContact) {
        // Everywhere Else
        gsap.to(hero, {
          opacity: 0,
          zIndex: -1,
          duration: 1,
        })
        gsap.to(heroText, {
          translateX: -40,
          duration: 1,
        })
        gsap.set(title, {
          top: nav.scrollHeight + 104,
          zIndex: 3,
        })
        gsap.to(title, {
          opacity: 1,
        })
        
        gsap.to(rightDots, {
          top: nav.scrollHeight + title.scrollHeight,
          right: -55,
          ease: Back.easeOut,
        });
        gsap.to(triangle, {
          top: nav.scrollHeight + title.scrollHeight + 55,
          right: 55,
          rotation: 180,
          ease: Back.easeOut,
        })
        gsap.to(blackBox, {
          top: nav.scrollHeight + title.scrollHeight + 48,
          height: 129,
          left: 67,
          rotation: 90,
          ease: Back.easeOut,
        })
        gsap.to(whiteRect, {
          top: nav.scrollHeight + title.scrollHeight + 37,
          right: 104,
          rotation: 90,
          ease: Back.easeOut,
        })
        gsap.to(blackRect, {
          opacity: 0,
          ease: Back.easeOut,
        })
        gsap.to(leftDots, {
          top: nav.scrollHeight + title.scrollHeight,
          scale: 1,
          ease: Back.easeOut,
        });

        gsap.to(header, {
          height: nav.scrollHeight + title.scrollHeight + 100,
        });

        gsap.to(highlight, {
          width: word.scrollWidth + 16,
          opacity: 1,
          ease: Back.easeOut,
        });
      } else if (isInContact) {
        gsap.fromTo(header, {
          height: 0,
        }, {
          height: 'auto',
        });
      }
    }

    return () => {
      if (titleString && highlight && word) {
        gsap.to(highlight, {
          width: 0,
          ease: Back.easeOut,
        });
      }
    };
  }, [
    titleString,
    headerRef,
    navRef,
    heroRef,
    heroTextRef,
    heroImageRef,
    titleRef,
    leftDotsRef,
    rightDotsRef,
    triangleRef,
    blackBoxRef,
    whiteRectRef,
    isInContact,
    isInHome,
  ]);

  return {
    highlightRef,
    wordRef,
    headerRef,
    navRef,
    heroRef,
    heroTextRef,
    heroImageRef,
    titleRef,
    leftDotsRef,
    rightDotsRef,
    triangleRef,
    blackBoxRef,
    blackRectRef,
    whiteRectRef,
  };
};

export default useHeaderAnimations;
