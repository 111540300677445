import React from 'react';

import './styles.scss';

function Icon({ name, color, size, className, ...rest }) {
  return (
    <span
      className={`material-icons icon icon___${color} icon___${size} ${className}`}
      {...rest}
    >
      {name}
    </span>
  );
}

export default Icon;
