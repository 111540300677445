export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/about',
  SERVICES: '/services',
  PORTFOLIO: '/portfolio',
  BLOGS: '/blogs',
  CONTACT_US: '/contact',
};

export const ROUTE_NAMES = {
  [ROUTES.HOME]: 'Home',
  [ROUTES.ABOUT_US]: 'About Us',
  [ROUTES.SERVICES]: 'Services',
  [ROUTES.PORTFOLIO]: 'Portfolio',
  [ROUTES.BLOGS]: 'Blogs',
  [ROUTES.CONTACT_US]: 'Contact Us',
};

export const LINKS = [
  {
    name: ROUTE_NAMES[ROUTES.ABOUT_US],
    to: ROUTES.ABOUT_US,
  },
  {
    name: ROUTE_NAMES[ROUTES.SERVICES],
    to: ROUTES.SERVICES,
  },
  {
    name: ROUTE_NAMES[ROUTES.PORTFOLIO],
    to: ROUTES.PORTFOLIO,
  },
  {
    name: ROUTE_NAMES[ROUTES.BLOGS],
    to: ROUTES.BLOGS,
  },
];

export const SERVICES = [
  {
    icon: 'code',
    title: 'Custom Software Development',
    description:
      'Our team of experienced software developers specialise in creating custom software applications tailored to meet the specific needs of your business. We work closely with you to understand your business processes and goals, and use the latest technologies to deliver solutions that drive results',
  },
  {
    icon: 'support_agent',
    title: 'Consulting Services',
    description:
      'In addition to custom software development, we also offer consulting services to help businesses optimize their operations and improve their use of technology. Our team of experts can provide guidance on a range of topics, including process improvement, technology selection, and project management',
  },
  {
    icon: 'shopping_cart',
    title: 'Software Maintenance and Support',
    description:
      'Once your custom software solution is up and running, we offer ongoing maintenance and support to ensure that it continues to meet the evolving needs of your business. Our team is always available to address any issues or make updates as needed',
  },
];

export const INFORMATION = [
  {
    icon: 'phone',
    text: '+6421702154',
  },
  {
    icon: 'email',
    text: 'r.softwarelab@gmail.com',
  },
  {
    icon: 'room',
    text: '1204/1 Greys Ave, CBD, Auckland',
  },
];

export const COST_QUESTIONS = [
  'What kind of project would you like to build?',
  'Have you already started on this project?',
  'Could you tell us a brief description of your idea for us to get a cost estimate?',
  'What platform do you want to run your app on?',
  'How will this project be funded?',
  'What phase are you currently at with this project?',
  'Almost there!',
]

export const PROJECT_TYPE_OPTIONS = [
  {
    icon: 'local_mall',
    value: 'E-commerce/ Shopify app',
  },
  {
    icon: 'code',
    value: 'Software Development',
  },
  {
    icon: 'palette',
    value: 'Software Design',
  },
  {
    icon: 'work',
    value: 'App for my business/company',
  },
  {
    icon: 'sms',
    value: 'Other',
  },
];

export const HAS_STARTED_OPTIONS = [
  {
    icon: 'thumb_up',
    value: 'Yes',
  },
  {
    icon: 'thumb_down',
    value: 'No',
  },
]

export const PLATFORM_OPTIONS = [
  {
    icon: 'android',
    value: 'Android',
  },
  {
    icon: 'apple',
    value: 'iOS',
  },
  {
    icon: 'language',
    value: 'Web',
  },
]

export const FUNDING_OPTIONS = [
  {
    value: 'From my personal funds',
  },
  {
    value: 'Friends and family or other business partners',
  },
  {
    value: 'Government Grants',
  },
  {
    value: 'Other',
  },
  {
    value: 'Through my Company or Employer',
  },
  {
    value: 'Prefer not to say',
  },
]

export const DEVELOPMENT_PHASE_OPTIONS = [
  {
    value: '1. Conceptualisation',
  },
  {
    value: '2. Feasibility',
  },
  {
    value: '3. Researching Solutions',
  },
  {
    value: '4. Ready to start!',
  },
]

export const PROJECT_TYPES = {
  SOFTWARE_DEVELOPMENT: 'Software Development',
  SOFTWARE_DESIGN: 'Software Design',
};
